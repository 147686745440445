import React from 'react';
import { EllipsisVertical } from '@procore/core-icons';

const ExtraOptions = () => {
  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <EllipsisVertical />
    </div>
  );
};

export default ExtraOptions;
