import {
  Blueprint,
  Building,
  ClipboardBulletedChecks,
  Excavator,
  PeopleGroup,
  Plus,
} from '@procore/core-icons';
import {
  Box,
  Card,
  colors,
  Dropdown,
  Flex,
  FlexList,
  H2,
  Pill,
  Typography,
  Link,
} from '@procore/core-react';
import React from 'react';
import image2 from '../../assets/image2.png';
import { saveConfig } from './config';
import DraggableModal from './DraggableModal';

const projectHeaderData = {
  project_id: 2022,
  project_name: 'Hack to the future',
  image: image2,
  weather_status: 'partlySunny',
  weather_temp: '87',
  address: '123 East Street, Austin, TX 73301',
  projectDetails: [
    {
      title: 'Building Size',
      icon: 'Blueprint',
      description: '740k sq ft',
    },
    {
      title: 'Stage',
      icon: 'ClipboardBulletedChecks',
      description: 'Course of Construction',
    },
    {
      title: 'Construction Type',
      icon: 'Excavator',
      description: 'Commercial',
    },
    {
      title: 'Companies',
      icon: 'Building',
      description: '12 companies',
    },
    {
      title: 'Workforce',
      icon: 'PeopleGroup',
      description: '48 people',
    },
  ],
};

function getIcon(icon: string) {
  if (icon === 'Blueprint')
    return <Blueprint size="lg" color={colors.gray45} />;
  if (icon === 'PeopleGroup')
    return <PeopleGroup size="lg" color={colors.gray45} />;
  if (icon === 'Building') return <Building size="lg" color={colors.gray45} />;
  if (icon === 'Excavator')
    return <Excavator size="lg" color={colors.gray45} />;
  if (icon === 'ClipboardBulletedChecks')
    return <ClipboardBulletedChecks size="lg" color={colors.gray45} />;
  return null;
}

export function ProjectSummary({
  largeWidgets,
  setLargeWidgets,
}: {
  largeWidgets: any;
  setLargeWidgets: any;
}) {
  const [open, setOpen] = React.useState(false);

  const SummaryHeader = () => {
    return (
      <Flex justifyContent="space-between">
        <Flex direction="column">
          <Box>
            <H2>{`${projectHeaderData.project_id} - ${projectHeaderData.project_name}`}</H2>
          </Box>
          <Box>
            <Typography>
              <Link href="#">{projectHeaderData.address}</Link>
            </Typography>
          </Box>
        </Flex>
        <FlexList space="lg">
          <Dropdown variant="primary" icon={<Plus />} label="Create">
            <Dropdown.Item item="file">Create RFI</Dropdown.Item>
            <Dropdown.Item item="folder">Create Submittal</Dropdown.Item>
            <Dropdown.Item item="folder">Create Work Order</Dropdown.Item>
            <Dropdown.Item item="folder">Create Purchase Order</Dropdown.Item>
            <Dropdown.Item item="folder">Create Prime Contract</Dropdown.Item>
          </Dropdown>
          <Dropdown>
            <Dropdown.Item item="Example">Edit Project</Dropdown.Item>
            <Dropdown.Item item="Example">Change Stage</Dropdown.Item>
            <Dropdown.Item item="Example">Make Inactive</Dropdown.Item>
            <Dropdown.Item item="settings" onClick={() => setOpen(true)}>
              Settings
            </Dropdown.Item>
            <Dropdown.Item item="Example">Configuration</Dropdown.Item>
          </Dropdown>
        </FlexList>
      </Flex>
    );
  };

  let unsavedChanges = {};

  function updateUnsavedChanges(tile: any) {
    unsavedChanges = {
      ...unsavedChanges,
      [tile.type]: {
        type: tile.type,
        hidden: tile.hidden,
      },
    };
  }

  function applySettings() {
    const newConfig = Object.values(unsavedChanges);
    saveConfig(newConfig);
    window.location.assign('/');
  }

  return (
    <>
      <Card style={{ background: 'white' }}>
        <FlexList>
          <Pill
            color="green"
            style={{
              position: 'absolute',
              left: '42px',
              top: '24px',
              margin: '0px',
            }}
          >
            ACTIVE
          </Pill>
          <Box style={{ marginLeft: '-20px' }}>
            <img
              src={projectHeaderData.image}
              alt="Logo"
              height="167px"
              width="277px"
            />
          </Box>
          <Box paddingTop="xl">
            <SummaryHeader />
            <Flex
              justifyContent="space-between"
              marginTop="xxl"
              direction="row"
            >
              {projectHeaderData.projectDetails.map((projectDetail) => (
                <Flex paddingRight="lg" style={{ minWidth: '250px' }}>
                  <Flex direction="column" paddingRight="md">
                    {getIcon(projectDetail.icon)}
                  </Flex>
                  <Flex direction="column">
                    <Typography intent="small" color="gray45">
                      {projectDetail.title}
                    </Typography>
                    <Typography intent="body" weight="bold">
                      {projectDetail.description}
                    </Typography>
                  </Flex>
                </Flex>
              ))}
            </Flex>
          </Box>
        </FlexList>
      </Card>
      <DraggableModal
        open={open}
        onClose={() => setOpen(false)}
        largeWidgets={largeWidgets}
        setLargeWidgets={setLargeWidgets}
        updateUnsavedChanges={updateUnsavedChanges}
        applySettings={applySettings}
      />
    </>
  );
}
