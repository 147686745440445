import React from 'react';
import { H3, Card, Typography, Button, Box } from '@procore/core-react';

export default function Reminders() {
  return (
    <Card
      style={{
        padding: '30px 24px',
        display: 'flex',
        flexDirection: 'column',
        gap: 20,
        marginTop: '1rem',
      }}
    >
      <div>
        <H3>Reminders</H3>
      </div>
      <Box
        style={{
          borderStyle: 'solid',
          borderColor: '#d9d9d9',
          borderRadius: '4px',
          background: '#F9FAFA',
          padding: '1rem',
        }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-start',
            flexDirection: 'column',
          }}
        >
          <H3>
            Consitent Daily Log’s result in better visibility and overall
            quality on a project
          </H3>
          <Typography italic intent="small" color="gray45">
            3 hours ago
          </Typography>
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
          }}
        >
          <Button variant="tertiary" style={{ marginRight: '1rem' }}>
            Remind me later
          </Button>
          <Button variant="secondary">Complete Daily Log</Button>
        </div>
      </Box>
    </Card>
  );
}
