const defaultConfig = {
  state: [
    {
      type: 'important_documents',
      title: 'Important Documents',
      hidden: true,
    },
    {
      type: 'my_work',
      title: 'My Work',
      hidden: true,
    },
    {
      type: 'project_teams',
      title: 'Project Teams',
      hidden: true,
    },
    {
      type: 'project_timeline',
      title: 'Project Timeline',
      hidden: true,
    },
    {
      type: 'reminders',
      title: 'Reminders',
      hidden: true,
    },
    {
      type: 'activity_feed',
      title: 'Activity Feed',
      hidden: true,
    },
  ],
};
export function getConfig() {
  const config = localStorage.getItem('projectConfig');
  return (config && JSON.parse(config)) || defaultConfig;
}

export function saveConfig(newConfig: any[]) {
  const config = getConfig() || defaultConfig;
  const unaffected = config.state.filter(
    (tile: any) => !newConfig.find((t) => t.type === tile.type)
  );
  const mergedConfig = {
    state: [...unaffected, ...newConfig],
  };
  localStorage.setItem('projectConfig', JSON.stringify(mergedConfig));
}

export function checkWithConfig(tileType: string) {
  const config = getConfig() || defaultConfig;

  const isHidden = config?.state?.find(
    (tile: any) => tile.type === tileType
  )?.hidden;

  return isHidden ?? false;
}
