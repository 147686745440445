import React from 'react';
import { Box, Typography, Link } from '@procore/core-react';

const Title = (props: { type: string; title: string }) => {
  return (
    <>
      <Box
        justifyContent="center"
        display="flex"
        flexDirection="column"
        marginTop="xs"
      >
        <Typography intent="small" color="gray45">
          {props.type}
        </Typography>
        <Typography weight="bold">
          <Link href="#">{props.title}</Link>
        </Typography>
      </Box>
    </>
  );
};

export default Title;
