import React from 'react';
import { Box, Typography } from '@procore/core-react';

const DueDate = (props: { dueDate: string }) => {
  return (
    <Box
      justifyContent="center"
      display="flex"
      flexDirection="column"
      marginTop="xs"
    >
      <Typography intent="small" color="gray45">
        Due date
      </Typography>
      <Typography weight="bold">{props.dueDate}</Typography>
    </Box>
  );
};

export default DueDate;
