import React, { useState, useCallback } from 'react';

import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import DraggableItem from './DraggableItem';
import { Button, FlexList, Modal } from '@procore/core-react';

const swapElements = (array: any, index1: any, index2: any) => {
  const oldCard = array[index2];
  array[index2] = array[index1];
  array[index1] = oldCard;
  return JSON.parse(JSON.stringify(array));
};

const renderCard = (
  card: any,
  index: number,
  onMoveCard: any,
  updateUnsavedChanges: (tile: any) => void
) => {
  return (
    <DraggableItem
      key={index}
      index={index}
      id={card.type}
      text={card.title}
      moveCard={onMoveCard}
      card={card}
      updateUnsavedChanges={updateUnsavedChanges}
    />
  );
};

const DraggableModal = ({
  open,
  onClose,
  largeWidgets,
  setLargeWidgets,
  updateUnsavedChanges,
  applySettings,
}: {
  open: boolean;
  onClose: any;
  largeWidgets: any;
  setLargeWidgets: any;
  applySettings: () => void;
  updateUnsavedChanges: (tile: any) => void;
}) => {
  const [cards, setCards] = useState(largeWidgets);

  const handleMoveCard = useCallback((dragIndex: any, hoverIndex: any) => {
    setCards((prevCards: any) =>
      swapElements(prevCards, dragIndex, hoverIndex)
    );
  }, []);

  const handleClose = () => {
    setLargeWidgets(cards);
    onClose();
  };

  const handleApply = () => {
    applySettings();
    setLargeWidgets(cards);
  };

  return (
    <DndProvider backend={HTML5Backend}>
      <span>
        <Modal
          onClickOverlay={handleClose}
          open={open}
          placement="top"
          style={{ marginTop: 100 }}
        >
          <Modal.Header onClose={handleClose}>Settings</Modal.Header>
          <Modal.Body style={{ minWidth: '550px' }}>
            <FlexList direction="column">
              <div>
                {cards.map((card: any, i: number) =>
                  renderCard(card, i, handleMoveCard, updateUnsavedChanges)
                )}
              </div>
            </FlexList>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleApply}>
              Apply
            </Button>
          </Modal.Footer>
        </Modal>
      </span>
    </DndProvider>
  );
};

export default DraggableModal;
