import React from 'react';
import { Card, H3, Button } from '@procore/core-react';
import { Activity, Blueprint, Book, Folder } from '@procore/core-icons';
const ImportantDocuments = () => {
  return (
    <Card
      style={{
        padding: '30px 24px',
        flexDirection: 'column',
        gap: 20,
        marginTop: '1rem',
      }}
    >
      <div>
        <H3>Important Documents</H3>
      </div>
      <Button
        icon={<Folder />}
        variant="secondary"
        style={{ marginRight: '1rem', marginTop: '1rem' }}
      >
        All Documents
      </Button>
      <Button
        icon={<Blueprint />}
        variant="secondary"
        style={{ marginRight: '1rem', marginTop: '1rem' }}
      >
        Drawings
      </Button>
      <Button
        icon={<Book />}
        variant="secondary"
        style={{ marginRight: '1rem', marginTop: '1rem' }}
      >
        Latest Specs
      </Button>
      <Button
        icon={<Activity />}
        variant="secondary"
        style={{ marginRight: '1rem', marginTop: '1rem' }}
      >
        Change Events
      </Button>
    </Card>
  );
};
export default ImportantDocuments;
