import React from 'react';
import { Pill } from '@procore/core-react';

interface PillData {
  color: 'gray' | 'yellow' | 'green' | 'red';
  label: string;
}

const getPillData = (status: string): PillData => {
  switch (status) {
    case 'draft':
      return { color: 'gray', label: 'DRAFT' };
    case 'under_review':
      return { color: 'yellow', label: 'UNDER REVIEW' };
    case 'published':
      return { color: 'green', label: 'PUBLISHED' };
    case 'work_required':
      return { color: 'red', label: 'WORK REQUIRED' };
    case 'open':
      return { color: 'green', label: 'OPEN' };
    default:
      return { color: 'gray', label: 'VOID' };
  }
};

const WorkStatus = (props: { status: string }) => {
  const { color, label } = getPillData(props.status);
  return (
    <div
      style={{
        display: 'flex',
        width: '100%',
        height: '100%',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Pill color={color}>{label}</Pill>
    </div>
  );
};

export default WorkStatus;
