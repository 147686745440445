import React from 'react';

const Icon = (props: { TypeIcon: any }) => {
  return (
    <div
      style={{
        display: 'flex',
        width: '100%',
        height: '100%',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <props.TypeIcon />
    </div>
  );
};

export default Icon;
