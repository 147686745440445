import React, { useState, useEffect } from 'react';
import { H3, Page, Card, Box } from '@procore/core-react';
import { ProjectSummary } from '@/widgets/ProjectSummary/ProjectSummary';
import MyWork from '@/widgets/MyWork';
import Reminders from '@/widgets/Reminders/Reminders';
import ImportantDocuments from '@/widgets/ImportantDocuments/ImportantDocuments';
import { getConfig } from '@/widgets/ProjectSummary/config';
import { loadFromLocalStorage, saveToLocalStorage } from '@/utils/localStorage';

export const largeWidgetData = [
  {
    type: 'important_documents',
    title: 'Important Documents',
  },
  {
    type: 'my_work',
    title: 'My Work',
  },
  {
    type: 'project_teams',
    title: 'Project Teams',
  },
  {
    type: 'project_timeline',
    title: 'Project Timeline',
  },
];

export const smallWidgetData = [
  {
    type: 'reminders',
    title: 'Reminders',
  },
  {
    type: 'activity_feed',
    title: 'Activity Feed',
  },
];

const Widget = (props: { index: number; widget: any }) => {
  const visible = getConfig()?.state.find(
    (tile: any) => tile.type === props.widget.type
  )?.hidden;
  let component;
  if (props.widget.type === 'my_work') {
    component = <MyWork key={props.index} />;
  }
  if (props.widget.type === 'reminders') {
    component = <Reminders key={props.index} />;
  }
  if (props.widget.type === 'important_documents') {
    component = <ImportantDocuments key={props.index} />;
  }
  if (!component) {
    console.log(props.widget.type);
    component = (
      <>
        <Card
          style={{
            background: 'white',
            marginTop: '1rem',
            marginBottom: '1rem',
          }}
        >
          <Box padding="xl" style={{ minHeight: '15rem' }}>
            <H3>{props.widget.title}</H3>
          </Box>
        </Card>
      </>
    );
  }

  return visible && component;
};

export function Home() {
  const [largeWidgets, setLargeWidgets] = useState([]);

  useEffect(() => {
    setLargeWidgets(loadFromLocalStorage('largeWidgets') || largeWidgetData);
  }, []);

  useEffect(() => {
    if (largeWidgets.length !== 0)
      saveToLocalStorage('largeWidgets', largeWidgets);
  }, [largeWidgets]);

  return (
    <>
      <div>
        <div
          style={{
            height: '50px',
            width: '50px',
            backgroundColor: '#FF5100',
            position: 'absolute',
          }}
        ></div>
        <div style={{ height: '50px', backgroundColor: 'black' }}></div>
      </div>
      <Page>
        <Page.Main>
          <Page.Body style={{ marginTop: 0, paddingTop: '1rem' }}>
            <Page.Row>
              <Page.Column>
                {largeWidgets.length > 0 && (
                  <ProjectSummary
                    largeWidgets={largeWidgets}
                    setLargeWidgets={setLargeWidgets}
                  />
                )}
              </Page.Column>
            </Page.Row>
            <Page.Row>
              <Page.Column colWidth={8}>
                <Box paddingRight="xs" paddingBottom="lg">
                  {largeWidgets.map((widget, index) => (
                    <Widget index={index} widget={widget} />
                  ))}
                </Box>
              </Page.Column>
              <Page.Column colWidth={4}>
                <Box paddingBottom="lg">
                  {smallWidgetData.map((widget, index) => (
                    <Widget index={index} widget={widget} />
                  ))}
                </Box>
              </Page.Column>
            </Page.Row>
          </Page.Body>
        </Page.Main>
      </Page>
    </>
  );
}
