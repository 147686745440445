import React from 'react';
import { H3, Card, Grid } from '@procore/core-react';
import {
  FileQuestionMark,
  FileBulletsCurrencyUSA,
  Blueprint,
  ClipboardPushpin,
  Stamp,
  EllipsisVertical,
} from '@procore/core-icons';
import Icon from './Icon';
import Title from './Title';
import DueDate from './DueDate';
import WorkStatus from './WorkStatus';
import ExtraOptions from './ExtraOptions';

const myWorkData = [
  {
    type: 'rfi',
    title: 'COURSE-6: Metal Stud Welding Alternative',
    status: 'draft',
    due_date: '10/20/2022',
  },
  {
    type: 'invoice',
    title: '#7: SC-001-002',
    status: 'under_review',
    due_date: '10/24/2022',
  },
  {
    type: 'drawings',
    title: 'Issued for Construction',
    status: 'published',
    due_date: '9/14/2022',
  },
  {
    type: 'punch_list',
    title: '#2: Paint Touch Up',
    status: 'work_required',
    due_date: '12/8/2022',
  },
  {
    type: 'submittals',
    title: '#19 Revision 0: Insulation Product Data',
    status: 'open',
    due_date: '11/3/2022',
  },
];

const getType = (type: string) => {
  switch (type) {
    case 'rfi':
      return { typeTitle: 'RFI', TypeIcon: FileQuestionMark };
    case 'invoice':
      return { typeTitle: 'Invoice', TypeIcon: FileBulletsCurrencyUSA };
    case 'drawings':
      return { typeTitle: 'Drawings', TypeIcon: Blueprint };
    case 'punch_list':
      return { typeTitle: 'Punch List', TypeIcon: ClipboardPushpin };
    case 'submittals':
      return { typeTitle: 'Submittals', TypeIcon: Stamp };
    default:
      return { typeTitle: 'Unknown', TypeIcon: FileQuestionMark };
  }
};

export default function MyWork() {
  return (
    <Card
      style={{
        padding: '30px 24px',
        display: 'flex',
        flexDirection: 'column',
        gap: 20,
        marginTop: '1rem',
      }}
    >
      <div
        style={{
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <H3>My Work</H3>
        <EllipsisVertical />
      </div>
      <div>
        {myWorkData.map((data, index) => {
          const { typeTitle, TypeIcon } = getType(data.type);
          return (
            <div
              key={index}
              style={{
                display: 'flex',
                alignItems: 'center',
                height: '73px',
                borderStyle: 'solid',
                borderBottomStyle:
                  index === myWorkData.length - 1 ? 'solid' : 'none',
                borderColor: '#d9d9d9',
              }}
            >
              <Grid style={{ minWidth: '100%' }}>
                <Grid.Row>
                  <Grid.Col colWidth={1}>
                    <Icon TypeIcon={TypeIcon} />
                  </Grid.Col>
                  <Grid.Col colWidth={5}>
                    <Title title={data.title} type={typeTitle} />
                  </Grid.Col>
                  <Grid.Col colWidth={3}>
                    <WorkStatus status={data.status} />
                  </Grid.Col>
                  <Grid.Col colWidth={2}>
                    <DueDate dueDate={data.due_date} />
                  </Grid.Col>
                  <Grid.Col colWidth={1}>
                    <ExtraOptions />
                  </Grid.Col>
                </Grid.Row>
              </Grid>
            </div>
          );
        })}
      </div>
    </Card>
  );
}
