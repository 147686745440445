import { Switch } from '@procore/core-react';
import React, { useState } from 'react';
import { checkWithConfig } from './config';

export function ToggleSwitch(props: {
  tile: any;
  updateUnsavedChanges: (tile: any) => void;
}) {
  const checked = checkWithConfig(props.tile.type);
  const [isChecked, setIsChecked] = useState(checked);
  return (
    <Switch
      checked={isChecked}
      onChange={() => {
        setIsChecked(!isChecked);
        props.updateUnsavedChanges({
          ...props.tile,
          hidden: !isChecked,
        });
      }}
    ></Switch>
  );
}
